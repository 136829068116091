import React from 'react';
import styled from 'styled-components';
import { ChatMessage, ChatContainer, ChatButton, InputChatContainer, ChatIcon } from '../sharedStyle';
import LinkedinIcon from '../assets/linkedin-logo.svg';
import XIcon from '../assets/x-logo.svg';
import CalendarIcon from '../assets/calendar-blank.svg';

const ChatInputContactDetails: React.FC = () => {
  return (
    <InputChatContainer>
      <ChatIcon></ChatIcon>
      <ChatContainer>
        <ChatMessage>
          You can also reach me on other platforms
          {/* <ChatButton as="a" href="https://cal.com/emile-courthoud/15min" target="_blank">
            <img src={CalendarIcon} alt="Icon" />
            Book a video call
          </ChatButton> */}
          <ChatButton as="a" href="https://www.linkedin.com/in/emile-courthoud/" target="_blank">
            <img src={LinkedinIcon} alt="Icon" />
            Connect on LinkedIn
          </ChatButton>
          <ChatButton as="a" href="https://twitter.com/emilec___" target="_blank">
            <img src={XIcon} alt="Icon" />
            DM me on X
          </ChatButton>
        </ChatMessage>
      </ChatContainer>
    </InputChatContainer>
  );
};

export default ChatInputContactDetails;
