// src/components/Navbar.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import emilec_icon from '../assets/emilec_icon.png';
import { NameText } from '../sharedStyle';

const NavbarContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  height: 120px;
  top: ${({ visible }) => (visible ? '0' : '-120px')};
  left: 0;
  width: 100%;
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(5px);
  border-bottom: 1px solid var(--secondary);
  transition: top 0.5s;
  z-index: 1000;
  display: flex;
  justify-content: center;
`;

const NavbarContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-width: 550px;
  width: 100%;

  img {
    width: 40px;
    height: 40px;
  }
`;

const Navbar: React.FC = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsNavbarVisible(scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <NavbarContainer visible={isNavbarVisible}>
      <NavbarContent>
        <img src={emilec_icon} alt="Icon" />
        <NameText>Emile Courthoud</NameText>
      </NavbarContent>
    </NavbarContainer>
  );
};

export default Navbar;
