import styled from 'styled-components';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  gap: 4px;

  p {
    margin: 0;
    font-size: 13px;
    color: var(--text-tertiary);
  }
`;

export const ChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: flex-start;
  background-color: var(--secondary);
  padding: 8px 12px;
  border-radius: 16px 16px 16px 4px;

  max-width: 300px;
`;
export const InputChatContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  margin-bottom: 10px;
`;

export const ChatButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  justify-content: center;
  font-weight: 500;
  background-color: var(--chat-button);
  color: white;
  padding: 8px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  img {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: var(--chat-button-hover);
  }
`;

export const ChatIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-top: auto;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

export const NameText = styled.p`
  margin: 0;
  font-weight: 500;
`;