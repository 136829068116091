import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';


function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Home Page */}
          <Route path="/" element={<HomePage />} />

          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;