import React, { useState } from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { ChatMessage, ChatContainer, ChatButton, InputChatContainer, ChatIcon } from '../sharedStyle';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Input = styled.input<{ isError: boolean }>`
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: #232323;
  font-size: 15px;
  color: white;

  &::placeholder {
    color: var(--text-tertiary);
  }

  &:focus {
    outline: none;
  }

  ${({ isError }) =>
    isError &&
    css`
      box-shadow: inset 0 0 0 1px var(--red);
    `}
`;

const TextArea = styled.textarea<{ isError: boolean }>`
  padding: 12px;
  border: none;
  border-radius: 6px;
  resize: vertical;
  background-color: #232323;
  font-size: 15px;
  font-family: inherit;
  color: white;

  &::placeholder {
    color: var(--text-tertiary);
  }

  &:focus {
    outline: none;
  }

  ${({ isError }) =>
    isError &&
    css`
      box-shadow: inset 0 0 0 1px var(--red);
    `}
`;

const ChatButtonSubmit = styled(ChatButton)`
  width: 100%;
  font-size: inherit;
`;

const ErrorMessage = styled.div`
  color: var(--red);
`;

const SuccessMessage = styled.div`
  color: var(--green);
`;


const TELEGRAM_API_URL = `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/sendMessage`;
const CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;

const ChatInputContactForm: React.FC = () => {
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [full_name, setFullName] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({
    email: false,
    message: false,
    full_name: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateFields = () => {
    const newErrors = {
      email: !email.trim(),
      message: !message.trim(),
      full_name: !full_name.trim(),
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateFields()) return;

    try {
      await axios.post(TELEGRAM_API_URL, {
        chat_id: CHAT_ID,
        text: `WEBSITE - CONTACT FORM\nName: ${full_name} (email: ${email})\nMessage:\n\n${message}`,
      });
      setErrorMessage('');
      setSuccessMessage("Thank you! I'll get back to you shortly.");
    } catch (error: any) {
      console.error('Error sending message:', error.message);
      setErrorMessage("Oops! Something went wrong. Please try again later or reach out to me directly at ecourthoud@gmail.com");
      setSuccessMessage('');
    }
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <InputChatContainer>
      <ChatIcon></ChatIcon>
      <ChatContainer>
        <ChatMessage>
          You can also just leave me a message and I'll try to get back to you as soon as possible.
          <FormContainer onSubmit={handleSendMessage}>
            {subscribed ? (
              <SuccessMessage>Thank you for reaching out. I will get back to you shortly</SuccessMessage>
            ) : (
              <>
                <Input
                  value={full_name}
                  type="text"
                  name="FULLNAME"
                  id="mce-FULLNAME"
                  placeholder="Your name"
                  onChange={handleFullNameChange}
                  isError={errors.full_name}
                />
                <Input
                  value={email}
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  placeholder="Your email"
                  onChange={handleEmailChange}
                  isError={errors.email}
                />
                <TextArea
                  value={message}
                  name="MESSAGE"
                  id="mce-MESSAGE"
                  placeholder="Your message"
                  rows={6}
                  onChange={handleMessageChange}
                  isError={errors.message}
                ></TextArea>
                <div>
                  <ChatButtonSubmit type="submit" name="subscribe" id="mc-embedded-subscribe">Send</ChatButtonSubmit>
                  {/* Real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                    <input type="text" name="b_your-username_usX" tabIndex={-1} value="" onChange={() => {}} />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                    <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
              </>
            )}
          </FormContainer>
        </ChatMessage>
      </ChatContainer>
    </InputChatContainer>
  );
};

export default ChatInputContactForm;
