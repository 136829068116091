// src/components/ChatInput.tsx

import React from 'react';
import { ChatMessage, ChatContainer, InputChatContainer, ChatIcon } from '../sharedStyle';
import emilec_icon from '../assets/emilec_icon.png';

interface ChatInputProps {
  message: string;
  setIcon: boolean;
  setName: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ message, setIcon, setName }) => {
  return (
    <InputChatContainer>
      <ChatIcon>{setIcon && <img src={emilec_icon} alt="Icon" /> }</ChatIcon>
      <ChatContainer>
      {setName && <p>Emile</p>}
      <ChatMessage>{message}</ChatMessage>
    </ChatContainer>
    </InputChatContainer>
    
  );
};

export default ChatInput;
