// src/components/Footer.tsx

import React from 'react';
import styled from 'styled-components';
import ArrowUp from '../assets/arrow-up.svg';

interface FooterProps {
  onClick: () => void;
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;


const ScrollUpButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: var(--chat-button);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  img {
    width: 20px;
    height: 20px;
  }
`;

const Footer: React.FC<FooterProps> = ({ onClick }) => {
  return (
    <FooterContainer>
      <ScrollUpButton onClick={onClick}>
        <img src={ArrowUp} alt="Icon" />
      </ScrollUpButton>
    </FooterContainer>
  );
};

export default Footer;
