// src/components/ChatOutput.tsx

import React from 'react';
import styled from 'styled-components';
import { ChatMessage, ChatContainer } from '../sharedStyle';

const ChatOutputContainer = styled(ChatContainer)`
  align-self: flex-end;
  text-align: right;
  margin-bottom: 20px;
  gap: 4px;

  p {
    text-size: inherit;
  }
`;

const ChatOutputMessage = styled(ChatMessage)`
  background-color: var(--primary);
  border-radius: 16px 16px 4px 16px;
  align-self: flex-end;
`;

interface ResponseProps {
  message: string;
}

const ChatOutput: React.FC<ResponseProps> = ({ message }) => {

  return (
    <ChatOutputContainer>
      <p>You</p>
      <ChatOutputMessage>
        {message}
      </ChatOutputMessage>
    </ChatOutputContainer>
  );
};

export default ChatOutput;