import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import emilec_icon from '../assets/emilec_icon.png';
import ChatOutput from './ChatOutput';
import ChatInput from './ChatInput';
import Navbar from './Navbar';
import Footer from './Footer';
import ChatInputEmail from './ChatInputEmail';
import ChatInputContactDetails from './ChatInputContactDetails';
import ChatInputContactForm from './ChatInputContactForm';
import { NameText } from '../sharedStyle';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: center;
  padding: 60px 20px 0 20px;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  width: 100%;
  min-height: 120vh;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 12px;
`;

const ChatItem = styled.div<{ isVisible: boolean }>`
  opacity: 0;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  ${({ isVisible }) => isVisible && `
    opacity: 1;
  `}
`;

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0 40px 0;
`;

const DividerLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: rgb(28, 28, 28);
`;

const DateText = styled.p`
  margin: 0 16px;
  font-size: 14px;
  color: var(--text-tertiary);
`;

const HomePage: React.FC = () => {
  const [chatItems, setChatItems] = useState<Array<JSX.Element>>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const initialDelay = 1000; // Initial delay in milliseconds
  const delayBetweenItems = 1200; // Delay between showing each item

  useEffect(() => {
    const itemsToRender = [
      <ChatInput key={0} message="Hey! I'm Emile, how can I help?" setIcon={true} setName={true} />,
      <ChatOutput key={1} message="How can we get in touch?" />,
      <ChatInputEmail key={2} />,
      <ChatInputContactDetails key={3} />,
      <ChatInputContactForm key={4} />,
    ];

    const timeout = setTimeout(() => {
      setChatItems(prevItems => [...prevItems, itemsToRender[currentIndex]]);
      setCurrentIndex(prevIndex => prevIndex + 1);
    }, initialDelay);

    const interval = setInterval(() => {
      if (currentIndex < itemsToRender.length) {
        setChatItems(prevItems => [...prevItems, itemsToRender[currentIndex]]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      } else {
        clearInterval(interval);
      }
    }, delayBetweenItems);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [currentIndex]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return (
    <MainContainer>
      <Navbar />
      <ChatContainer>
        <Icon src={emilec_icon} alt="Icon" />
        <NameText>Emile Courthoud</NameText>
        <p>Ex founder <a href="https://www.nebuly.com/" target="_blank" rel="noopener noreferrer">@Nebuly</a> <a href="https://ethz.ch/en.html" target="_blank" rel="noopener noreferrer">@ETH Zürich</a></p>
        <DividerContainer>
          <DividerLine />
          <DateText>{formattedDate}</DateText>
          <DividerLine />
        </DividerContainer>
        {chatItems.map((item, index) => (
          <ChatItem key={index} isVisible={index <= currentIndex}>{item}</ChatItem>
        ))}
      </ChatContainer>
      <Footer onClick={scrollToTop} />
    </MainContainer>
  );
};

export default HomePage;
