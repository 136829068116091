import React, { useState } from 'react';
import styled from 'styled-components';
import { ChatMessage, ChatContainer, ChatButton, InputChatContainer, ChatIcon } from '../sharedStyle';
import copyIcon from '../assets/copy.svg';
import checkIcon from '../assets/check.svg';

const ChatButtonWrapper = styled.div`
  display: inline-flex;
`;

const StyledChatButton = styled(ChatButton)`
  background-color: var(--chat-button);
  font-size: inherit;
  width: 100%;
`;

const ChatInputEmail: React.FC = () => {
  const [copied, setCopied] = useState(false);

  const handleCopyEmail = () => {
    const email = 'ecourthoud@gmail.com'; // Your email address here
    navigator.clipboard.writeText(email)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error('Error copying email: ', error);
      });
  };

  return (
    <InputChatContainer>
      <ChatIcon></ChatIcon>
      <ChatContainer>
        <p>Emile</p>
        <ChatMessage>
          My email is ecourthoud@gmail.com
          <ChatButtonWrapper>
            <StyledChatButton onClick={handleCopyEmail}>
              <img src={copied ? checkIcon : copyIcon} alt="Icon" />
              {copied ? 'Copied' : 'Copy'}
            </StyledChatButton>
          </ChatButtonWrapper>
        </ChatMessage>
      </ChatContainer>
    </InputChatContainer>
  );
};

export default ChatInputEmail;
